<template>
    <el-scrollbar height="100vh">
        <div style="position: relative; display: flex; flex-direction: column">
            <div class="game-container">
                <div :class="isMobile ? 'contentViewMobile' : 'contentView'">
                    <p class="text-center">Privacy Policy</p>
                    <div>
                        <p class="text-right">        
                            Released: Jan 5, 2019<br/>
                            Last Update: Feb 27, 2023
                        </p>
                    </div>
                    <p>Who is collecting the data?</p>
                    <p>catizen lab, (hereinafter referred to as: “catizen”, “we”, or “us”) acts as Data Controller and recognizes its 
                        responsibilities in relation to the collection, holding, processing, use and/or transfer of personal data under the 
                        national applicable laws and regulations and the Regulation (EU) 2016/679 (hereinafter referred to as: “General 
                        Data Protection Regulation” or “GDPR”). Personal data will be collected only for lawful and relevant purposes 
                        and all practicable steps will be taken to ensure that personal data held by us is accurate. We will use your personal 
                        data which we may collect in accordance with this Privacy Notice. Your personal data shall be processed lawfully, 
                        fairly and in a transparent manner. We collect your personal data to provide you with our services, whereby by 
                        services we mean conducting of marketing activities, i.e. sending promotional contents through e-mail under your
                        consent (hereinafter referred to as: “the Services”).</p>

                    <p>We collect information from you when you fill out a form. When ordering or registering on our site, as appropriate,
                        you may be asked to enter your name or e-mail address. You may, however, visit our site anonymously.</p>

                    <p>What do we use your information for?</p>
                    <p>Any of the information we collect from you may be used in one of the following ways:</p>

                    <p>- To personalize your experience</p>

                    <p>- To improve our website</p>

                    <p>- To improve customer service</p>

                    <p>- To process transactions</p>

                    <p>- To administer a contest, promotion, survey or other site feature</p>

                    <p>- To send periodic emails</p>

                    <p>The email address you provide may be used to send you information, respond to inquiries, and/or other requests 
                        or questions.</p>

                    <p>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other 
                        company for any reason whatsoever, without your consent, other than for the express purpose of delivering the 
                        purchased product or service requested.</p>

                    <p>How do we protect your information?</p>
                    <p>We implement a variety of security measures to maintain the safety of your personal information when you place
                        an order or enter, submit, or access your personal information.</p>

                    <p>We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket 
                        Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by 
                        those authorized with special access rights to such systems, and are required to keep the information confidential.</p>

                    <p>After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be 
                        stored on our servers.</p>

                    <p>Do we use cookies?</p>
                    <p>We do not use cookies.</p>

                    <p>Do we disclose any information to outside parties?</p>
                    <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does 
                        not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, 
                        so long as those parties agree to keep this information confidential. We may also release your information when we
                        believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, 
                        property, or safety. However, non-personally identifiable visitor information may be provided to other parties for 
                        marketing, advertising, or other uses.</p>

                    <p>Third party links</p>
                    <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These 
                        thirdparty sites have separate and independent privacy policies. We therefore have no responsibility or liability 
                        for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and 
                        welcome anyfeedback about these sites.</p>

                    <p>Terms and Conditions</p>
                    <p>Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability 
                        governingthe use of our website at https://www.catizen.ai/</p>

                    <p>Your Consent</p>
                    <p>By using our site, you consent to our online privacy policy.</p>

                    <div class="rightView">
                        <p>catizen.ai-catizen. - All Rights Reserved © 2024</p>
                        <p>Copyright © 2024</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-block d-flex justify-content-between align-items-center">
            <div class="left" >
                <div class="copyright" >
                    ©2024 META MANOR.COPYRIGHT
                </div>
                <div class="bottom-link-block" >
                    <a href="/privacy" class="link-item" style="color: #494949;" :style="{marginLeft: isMobile ? '0.3rem' : '0'}">Privacy Policy</a>
                </div>
            </div>
            <div class="right d-flex" >
                <a href="https://t.me/CatizenAnn" target="_blank">
					<img src="../official/static/images/home-right-telegram.png" :style="{width: isMobile ? '0.5rem' : '.23rem'}"/>
				</a>
				<a href="https://twitter.com/CatizenAI" target="_blank">
					<img src="../official/static/images/home-right-twitter.png" :style="{width: isMobile ? '0.5rem' : '.23rem'}"/>
				</a>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
    name: "PrivacyPage",
    setup() {
        const isMobile = ref(window.innerWidth < 1141); // 初始根据屏幕宽度设置是否为移动端

        // 监听窗口大小变化，动态更新 isMobile 的值
        const handleResize = () => {
            isMobile.value = window.innerWidth < 1141;
        };
        
        // 添加窗口大小变化的事件监听器
        onMounted(() => {
            window.addEventListener("resize", handleResize);
        });

        // 移除窗口大小变化的事件监听器
        onUnmounted(() => {
            window.removeEventListener("resize", handleResize);
        });

        return {
            isMobile,
        };
    },
});
</script>

<style>
.contentView {
    width: 7.5rem;
    background: rgba(255, 252, 241, 0.81);
    border-radius: 0.89rem;
    margin: 1.05rem auto 1.38rem;
    padding: 0.3rem 0.2rem;
}
.contentView p {
    font-size: 0.16rem;
    padding: 0 0.16rem;
    margin-bottom: 0.1rem;
}
.text-right {
    text-align: right;
}
.contentView .rightView {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: end;
}
.contentView .rightView p {
    width: 3.3rem;
}


.contentViewMobile {
    width: 90%;
    background: rgba(255, 252, 241, 0.81);
    border-radius: 0.89rem;
    margin: 3.05rem auto 1.38rem;
    padding: 0.3rem 0.2rem;
}
.contentViewMobile p {
    font-size: 0.36rem;
    padding: 0 0.16rem;
    margin-bottom: 0.1rem;
}

.contentViewMobile .rightView {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: end;
}
.contentViewMobile .rightView p {
    width: 7.1rem;
}
</style>
