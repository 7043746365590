<template>
    <div class="menu-index d-flex align-items-center">
        <div v-if="!isMobile" class="d-flex justify-content-between" style="height: 100%; width: 100%">
            <a href="/" class="logo-wrapper d-flex align-items-center">
                <span class="logo-space" style="height: 100%; display: flex;align-items: center;">
                    <img src="../official/static/images/logo.png" class="logo button-effect" />
                </span>
            </a>
            <div class="d-flex pc-nav-index align-items-center" style="flex: 1 1 0%">
                <div class="d-flex align-items-center">
                    <a href="/" class="pc-nav-link-item">
                        <div :class="{ 'active': currentRoute.path === '/' }" class="button-border">Home</div>
                    </a>
                    <a href="/game" class="pc-nav-link-item">
                        <div :class="{ 'active': currentRoute.path === '/game' }" class="button-border">Game</div>
                    </a>
                    <a href="#">
                        <div class="whitepaperBtn d-flex align-items-center justify-content-center">
                            Whitepaper
                            <img src="../official/static/images/arrow.png" />
                        </div>
                    </a>
                    <div class="conmingSoon">conming soon</div>
                </div>
                <a class="play-free-btn" href="https://t.me/MetaManorbot/metamanor">Play for free</a>
            </div>
        </div>

        <div v-if="isMobile" class="d-flex justify-content-between align-items-center" style="height: 100%; width: 100%;">
            <div class="d-flex justify-content-center align-items-center" >
                <img @click="drawerVisible = !drawerVisible" src="../official/static/images/nav-mobile-open.png" class="nav-mobile-open-img" >
            </div>
            <a class="play-free-btn" href="https://t.me/MetaManorbot/metamanor" style="width: 2.3rem;height: 0.8rem;font-size: 0.32rem;border-radius: 0.4rem;">Play for free</a>
        </div>

        <div v-if="isMobile && drawerVisible" class="full-menu show">
            <div class="menu-list">
                <div class="d-flex align-items-center justify-content-between mobile-menu-top">
                    <a href="/" >
                        <img class="nav-menu-mobile-logo" src="../official/static/images/nav-mobile-logo.png" >
                    </a>
                    <img @click="drawerVisible = !drawerVisible" class="logo-nav-menu-close" src="../official/static/images/nav-close-mobile.png" >
                </div>
                <ul >
                    <li class="menu-content" >
                        <a :class="{ 'active': currentRoute.path === '/' }" href="/" >HOME</a>
                    </li>
                    <li class="menu-content" >
                        <a :class="{ 'active': currentRoute.path === '/game' }" href="/game" >GAME</a>
                    </li>
                    <li class="menu-content" >
                        <a href="#" >Whitepaper</a>
                    </li>
                </ul>
            </div>
            <div  @click="drawerVisible = !drawerVisible" style="flex: 1 1 0%; height: 100%;"></div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    props: {
        currentRoute: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const router = useRouter();
        const isMobile = ref(window.innerWidth < 1141); // 初始根据屏幕宽度设置是否为移动端
        const drawerVisible = ref(false); // 控制侧边抽屉的显示状态
        const activeIndex = ref("/"); // 默认激活的菜单项

        // 监听窗口大小变化，动态更新 isMobile 的值
        const handleResize = () => {
            isMobile.value = window.innerWidth < 1141;
        };

        // 添加窗口大小变化的事件监听器
        onMounted(() => {
            window.addEventListener("resize", handleResize);
        });

        // 移除窗口大小变化的事件监听器
        onUnmounted(() => {
            window.removeEventListener("resize", handleResize);
        });

        const handleSelect = (index) => {
            activeIndex.value = index;
            router.push(index); // 路由跳转
            if (isMobile.value) {
                drawerVisible.value = false; // 移动端选择完菜单项后，关闭抽屉
            }
        };

        return {
            isMobile,
            drawerVisible,
            activeIndex,
            handleSelect,
        };
    },
});
</script>

<style scoped>
/* 可以根据需要添加自定义的样式 */
.responsive-nav {
    padding: 10px;
}

.whitepaperBtn {
    width: 1.19rem;
    height: 0.2rem;
    line-height: 0.2rem;
    background: #FF7E00;
    border-radius: 0.1rem;
    font-size: 0.16rem;
    color: #FFFFFF;
    margin-left: .25rem;
}

.whitepaperBtn>img {
    width: 0.15rem;
    height: 0.15rem;
    margin-left: .09rem;
}

.conmingSoon {
    width: 0.52rem;
    height: 0.21rem;
    line-height: 0.16rem;
    text-align: center;
    font-size: 0.1rem;
    color: #FF7E00;
    background-image: url('../official/static/images/conming_bg.png');
    background-size: 100% 100%;
    position: relative;
    bottom: 0.1rem;
    left: -0.05rem;
    z-index: 1;
}
</style>
