import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/Home.vue";
import GamePage from "../views/Game.vue";
import PrivacyPage from "../views/Privacy.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomePage,
    },
    {
        path: "/game",
        name: "GamePage",
        component: GamePage,
    },
    {
        path: "/privacy",
        name: "PrivacyPage",
        component: PrivacyPage,
    }
];

const router = createRouter({
    history: createWebHistory("/"),
    routes: routes,
});

export default router;
