<template>
    <el-scrollbar style="height: 100%;position: fixed;width: 100%;">
        <div style="position: relative; display: flex; flex-direction: column">
            <div class="game-container">
                <div class="content">
                    <div class="top-block"></div>

                    <!-- <div class="page-title-with-right-block">
                        <div class="page-title-block mb0">
                            <span class="title">RECENTLY</span>
                            <div class="title-line"></div>
                        </div>
                    </div>

                    <div class="recently-block">
                        <div class="item-recently">
                            <div class="image-block">
                                <img
                                    src="../official/static/images/minigame/icon/32.png"
                                />
                            </div>
                            <div class="name">Ladder Run</div>
                        </div>
                        <div class="item-recently">
                            <div class="image-block">
                                <img
                                    src="../official/static/images/minigame/icon/41.png"
                                />
                            </div>
                            <div class="name">Shooting Cubes</div>
                        </div>
                        <div class="item-recently">
                            <div class="image-block">
                                <img
                                    src="../official/static/images/minigame/icon/2.png"
                                />
                            </div>
                            <div class="name">Fruit Master</div>
                        </div>
                        <div class="item-recently">
                            <div class="image-block">
                                <img
                                    src="../official/static/images/minigame/icon/1.png"
                                />
                            </div>
                            <div class="name">Smash Bubble</div>
                        </div>
                        <div class="item-recently">
                            <div class="image-block">
                                <img
                                    src="../official/static/images/minigame/icon/33.png"
                                />
                            </div>
                            <div class="name">Magic puzzle</div>
                        </div>
                    </div> -->

                    <div class="page-title-with-right-block">
                        <div class="page-title-block mb0">
                            <span class="title">TRENDING</span>
                            <div class="title-line"></div>
                        </div>
                        <!-- <div class="right-block">
                            <div class="el-select el-select--large">
                                <div class="select-trigger el-tooltip__trigger el-tooltip__trigger">
                                    <div class="el-input el-input--large el-input--suffix">
                                        <input
                                            class="el-input__inner"
                                            type="text"
                                            readonly=""
                                            autocomplete="off"
                                            placeholder="Type"
                                        /><span class="el-input__suffix"
                                            ><span
                                                class="el-input__suffix-inner"
                                                ><i
                                                    class="el-icon el-select__caret el-input__icon"
                                                    ><svg
                                                        class="icon"
                                                        width="200"
                                                        height="200"
                                                        viewBox="0 0 1024 1024"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M488.832 344.32l-339.84 356.672a32 32 0 000 44.16l.384.384a29.44 29.44 0 0042.688 0l320-335.872 319.872 335.872a29.44 29.44 0 0042.688 0l.384-.384a32 32 0 000-44.16L535.168 344.32a32 32 0 00-46.336 0z"
                                                        ></path></svg></i></span
                                        ></span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="collapsed search d-flex align-items-center justify-content-center"
                            >
                                <svg
                                    class="icon-search"
                                    width="13px"
                                    height="13px"
                                    viewBox="0 0 13 13"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                    <g
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                    >
                                        <g
                                            transform="translate(-1339.000000, -359.000000)"
                                            stroke="currentColor"
                                            stroke-width="1.5"
                                        >
                                            <g
                                                transform="translate(1339.000000, 359.000000)"
                                            >
                                                <circle
                                                    cx="5"
                                                    cy="5"
                                                    r="4.25"
                                                ></circle>
                                                <line
                                                    x1="8.5"
                                                    y1="8.5"
                                                    x2="11.3284271"
                                                    y2="11.3284271"
                                                    stroke-linecap="round"
                                                ></line>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div> -->
                    </div>

                    <div class="trending-list">
						<div class="item-trending" v-for="(item, index) in list" :key="index">
                            <img class="image" :src="item.img"/>
                            <div class="info-block">
                                <img class="icon" :src="item.icon"/>
                                <div class="text-block">
                                    <div class="box">
                                        <div class="name">
                                            {{item.name}}
                                        </div>
                                        <div class="category">{{item.type}}</div>
                                    </div>
                                    <div class="btn btnnoplay">Coming soon</div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="item-trending">
                            <img
                                class="image"
                                src="../official/static/images/minigame/image/1.png"
                                alt="Smash Bubble"
                            />
                            <div class="info-block">
                                <img
                                    class="icon"
                                    src="../official/static/images/minigame/icon/1.png"
                                />
                                <div class="text-block">
                                    <div class="box">
                                        <div class="name">Smash Bubble</div>
                                        <div class="category">
                                            Hyper Casual Games
                                        </div>
                                    </div>
                                    <div class="btn">Play</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-block d-flex justify-content-between align-items-center">
            <div class="left" >
                <div class="copyright" >
                    ©2024 META MANOR.COPYRIGHT
                </div>
                <div class="bottom-link-block" >
                    <a href="#" class="link-item" style="color: #494949;" :style="{marginLeft: isMobile ? '0.3rem' : '0'}">Privacy Policy</a>
                    <!-- <a href="/privacy" class="link-item" style="color: #494949;" :style="{marginLeft: isMobile ? '0.3rem' : '0'}">Privacy Policy</a> -->
                </div>
            </div>
            <div class="right d-flex" >
                <a href="https://www.youtube.com/@MetaManor_Official" target="_blank">
					<img src="../official/static/images/youtube.png" style="object-fit: contain;" :style="{width: isMobile ? '0.5rem' : '.23rem'}"/>
				</a>
                <a href="https://t.me/MetaManor" target="_blank">
					<img src="../official/static/images/home-right-telegram.png" :style="{width: isMobile ? '0.5rem' : '.23rem'}"/>
				</a>
				<a href="https://x.com/MetaManor_" target="_blank">
					<img src="../official/static/images/home-right-twitter.png" :style="{width: isMobile ? '0.5rem' : '.23rem'}"/>
				</a>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
    name: "GamePage",

    setup() {
        const isMobile = ref(window.innerWidth < 1141); // 初始根据屏幕宽度设置是否为移动端
		const list = ref([
			{
				img: require('../official/static/images/game/game_1.png'),
				icon: require('../official/static/images/game/game_1.png'),
				name: 'SquadBusters',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_2.png'),
				icon: require('../official/static/images/game/game_2.png'),
				name: 'Fruit Master',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_3.png'),
				icon: require('../official/static/images/game/game_3.png'),
				name: 'Ladder Run',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_4.png'),
				icon: require('../official/static/images/game/game_4.png'),
				name: 'Magic puzzle',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_5.png'),
				icon: require('../official/static/images/game/game_5.png'),
				name: 'Minecraftblock',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_6.png'),
				icon: require('../official/static/images/game/game_6.png'),
				name: 'Run Sausage Run',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_7.png'),
				icon: require('../official/static/images/game/game_7.png'),
				name: 'NEON BIKER',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_8.png'),
				icon: require('../official/static/images/game/game_8.png'),
				name: 'Super Cannon',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_9.png'),
				icon: require('../official/static/images/game/game_9.png'),
				name: 'Jewellegend',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_10.png'),
				icon: require('../official/static/images/game/game_10.png'),
				name: 'Furious Racing',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_11.png'),
				icon: require('../official/static/images/game/game_11.png'),
				name: 'Flow Deluxe',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_12.png'),
				icon: require('../official/static/images/game/game_12.png'),
				name: 'Shooting Cubes',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_13.png'),
				icon: require('../official/static/images/game/game_13.png'),
				name: 'Deadly Race',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_14.png'),
				icon: require('../official/static/images/game/game_14.png'),
				name: 'TAP HELI TAP',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_15.png'),
				icon: require('../official/static/images/game/game_15.png'),
				name: 'Tap Tap Colors',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_16.png'),
				icon: require('../official/static/images/game/game_16.png'),
				name: 'Tap Tap duck',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_17.png'),
				icon: require('../official/static/images/game/game_17.png'),
				name: 'Garden Tales',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_18.png'),
				icon: require('../official/static/images/game/game_18.png'),
				name: 'Bubble Shooter',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_19.png'),
				icon: require('../official/static/images/game/game_19.png'),
				name: 'Mr Bullet',
				type: 'Hyper Casual Games',
			},{
				img: require('../official/static/images/game/game_20.png'),
				icon: require('../official/static/images/game/game_20.png'),
				name: 'Mr Bullet 2',
				type: 'Hyper Casual Games',
			},
		]);

        // 监听窗口大小变化，动态更新 isMobile 的值
        const handleResize = () => {
            isMobile.value = window.innerWidth < 1141;
        };
        
        // 添加窗口大小变化的事件监听器
        onMounted(() => {
            window.addEventListener("resize", handleResize);
        });

        // 移除窗口大小变化的事件监听器
        onUnmounted(() => {
            window.removeEventListener("resize", handleResize);
        });

        return {
            isMobile,
			list,
        };
    },
});
</script>

<style>
a {
	display: inline-flex;
}
.menu-index {
    display: flex;
    align-items: center;
}
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
</style>
