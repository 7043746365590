<template>
    <div v-if="!isMobile">
        <div id="root">
            <div class="home" style="background-size: 100%;">
                <div
                    direction="vertical"
                    trigger="click"
                    :autoplay="false"
                    :loop="false"
                    height="100vh"
                    ref="swiper"
                >
                    <div class="section">
                        <div class="page1-box">
                            <div class="d-flex flex-column page1-left">
                                <img
                                    src="../official/static/images/index-logo.png"
                                    class="page-logo"
                                />
                                <span class="title">
                                    play for token，build your manor!
                                </span>
                                <img class="page1-people" src="../official/static/images/icon_4.png">
                            </div>
                            <img class="page1-video" src="../official/static/images/icon_5.png">
                        </div>
                        <!-- <div class="home-list">
                            <div class="data-view">
                                <div>Users</div>
                                <div class="desc">22,937,190</div>
                            </div>
                            <div class="data-view">
                                <div>Daily Users</div>
                                <div class="desc">1,627,148</div>
                            </div>
                            <div class="data-view">
                                <div>Onchain Users</div>
                                <div class="desc">1,215,097</div>
                            </div>
                            <div class="data-view">
                                <div>Onchain Txs</div>
                                <div class="desc">13,714,997</div>
                            </div>
                            <div class="data-view">
                                <div>X Followers</div>
                                <div class="desc">1,735,496</div>
                            </div>
                            <div class="data-view">
                                <div>Channel subscribers</div>
                                <div class="desc">4,486,779</div>
                            </div>
                        </div> -->

                        <div class="isPage2 is-active is-animating d-flex justify-content-between" style="transform: translateX(0px) scale(1);align-items: center;">
                            <div class="p3-carousel-left">
                                <img src="../official/static/images/logo.png" />
                                <div class="desc">
                                    <div>
                                        Telegram Mini Game
                                        Where every game leads
                                        to an airdrop adventure
                                        Play and trade using TG
                                        Game Bot
                                    </div>
                                </div>
                                <a class="playbtn" href="/game">Explore game</a>
                            </div>
                            <div class="p3-carousel-right" style="margin-top: 0;margin-bottom: 0;height: auto;">
                                <div>
                                    <img src="../official/static/images/p3line01.png" />
                                </div>
                                <div>
                                    <img src="../official/static/images/p3line02.png" />
                                </div>
                                <div>
                                    <img src="../official/static/images/p3line03.png" />
                                </div>
                                <div>
                                    <img src="../official/static/images/p3line04.png" />
                                </div>
                                <div>
                                    <img src="../official/static/images/p3line05.png" />
                                </div>
                                <div>
                                    <img src="../official/static/images/p3line06.png" />
                                </div>
                            </div>
                        </div>

                        <div class="page4">
                            <div class="page-common-top d-flex flex-column align-items-center">
                                <div class="page-common-title">Headline</div>
                            </div>
                            <div class="slider-block">
                                <div class="slider step-item-height d-flex align-items-center justify-content-center">
                                    <div class="line0"></div>
                                    <div class="step-item d-flex flex-column align-items-center">
                                        <div class="step-item-half-top step-item-half d-flex flex-column justify-content-end width-1000">
                                            <div class="desc desc1">
                                                Game Launch and User Activation<br />
                                                1.Launch the game and start a 100-day new user acquisition campaign.<br />
                                                2.Players can earn points by inviting friends and playing the game.<br />
                                                3.These points will be exchanged for tokens after the campaign ends.
                                                model.
                                            </div>
                                            <div class="step-top d-flex justify-content-center">
                                                <span class="inner">2024 Q3</span>
                                            </div>
                                        </div>
                                        <div class="line"></div>
                                        <div class="step-item-half-bottom step-item-half notranslate d-flex flex-column justify-content-center">
                                            <div class="step-image-block">
                                                <img class="img-step1" />
                                            </div>
                                        </div>
                                        <img class="img-catfoot" src="../official/static/images/page4-step-catfoot.png"/>
                                    </div>
                                    <div class="step-item d-flex flex-column align-items-center" >
                                        <div class="step-item-half-top step-item-half notranslate d-flex flex-column justify-content-end width-1000">
                                            <div class="step-image-block">
                                                <img class="img-step2" />
                                            </div>
                                        </div>
                                        <div class="line"></div>
                                        <div class="step-item-half-bottom step-item-half d-flex flex-column justify-content-start">
                                            <div class="step-bottom d-flex justify-content-center">
                                                <span class="inner">2024 Q4</span>
                                            </div>
                                            <div class="desc desc2">
                                                Token Issuance and Trading<br />
                                                1.End the 100-day campaign and issue tokens to players based on their accumulated points. <br />
                                                2.List the tokens on an exchange, enabling free trading for players.<br />
                                                3.Use the revenue accumulated during the campaign to buy back and burn some tokens, maintaining their scarcity and value.
                                            </div>
                                        </div>
                                        <img class="img-catfoot" src="../official/static/images/page4-step-catfoot.png"/>
                                    </div>
                                    <div class="step-item d-flex flex-column align-items-center">
                                        <div class="step-item-half-top step-item-half d-flex flex-column justify-content-end width-1000">
                                            <div class="desc desc3">
                                                In-Game Advertising and Economic Loop<br />
                                                1.Introduce an in-game advertising system, allowing brands to reach players through targeted ads.<br />
                                                2.Use the revenue generated from in-game purchases and advertising to continuously buy back and burn tokens.<br />
                                                3.Establish a sustainable economic loop, where the revenue generated from the game is used to maintain the token economy.
                                            </div>
                                            <div class="step-top d-flex justify-content-center">
                                                <span class="inner">2025 Q1</span>
                                            </div>
                                        </div>
                                        <div class="line"></div>
                                        <div class="step-item-half-bottom step-item-half notranslate d-flex flex-column justify-content-center">
                                            <div class="step-image-block">
                                                <img class="img-step3" />
                                            </div>
                                        </div>
                                        <img class="img-catfoot" src="../official/static/images/page4-step-catfoot.png"/>
                                    </div>
                                    <div class="step-item d-flex flex-column align-items-center" >
                                        <div class="step-item-half-top step-item-half notranslate d-flex flex-column justify-content-end width-1000">
                                            <div class="step-image-block">
                                                <img class="img-step4" />
                                            </div>
                                        </div>
                                        <div class="line"></div>
                                        <div class="step-item-half-bottom step-item-half d-flex flex-column justify-content-start">
                                            <div class="step-bottom d-flex justify-content-center">
                                                <span class="inner">2025 Q2</span>
                                            </div>
                                            <div class="desc desc2">
                                                Game Development and Platform Expansion<br />
                                                1.Invite other game developers to join the platform, expanding the range of games and creating a one-stop gaming hub. <br />
                                                2.Continuously improve and refine the platform, adding new features and functionalities to enhance the gaming experience.<br />
                                                3.Focus on building a professional game channel and ecosystem, offering a comprehensive gaming experience for players.
                                            </div>
                                        </div>
                                        <img class="img-catfoot" src="../official/static/images/page4-step-catfoot.png"/>
                                    </div>
                                    <div class="line0"></div>
                                </div>
                            </div>
                        </div>

                        <div class="page6">
                            <div class="page6Title">
                                <div class="page-common-title">Partner</div>
                            </div>
                            <div class="partners-grid">
                                <div class="partner-item">
                                    <img src="../official/static/images/Telegram.png" />
                                </div>
                                <div class="partner-item">
                                    <img src="../official/static/images/Trx.png" />
                                </div>
                                <div class="partner-item">
                                    <img src="../official/static/images/Ton.png" />
                                </div>
                                <div class="partner-item">
                                    <img src="../official/static/images/Solana.png" />
                                </div>
                            </div>
                        </div>
                        
                        <div class="bottom-block d-flex justify-content-between align-items-center">
                            <div class="left">
                                <div class="copyright">©2024 META MANOR.COPYRIGHT</div>
                                <div class="bottom-link-block d-flex">
                                    <div class="d-flex">
                                        <a href="#" class="link-item">Privacy Policy</a>
                                        <!-- <a href="/privacy" class="link-item">Privacy Policy</a> -->
                                    </div>
                                </div>
                            </div>
                            <div class="right d-flex">
                                <a href="https://www.youtube.com/@MetaManor_Official" target="_blank">
                                    <img src="../official/static/images/youtube.png" style="object-fit: contain;" />
                                </a>
                                <a href="https://t.me/MetaManor" target="_blank">
                                    <img src="../official/static/images/home-right-telegram.png" />
                                </a>
                                <a href="https://x.com/MetaManor_" target="_blank">
                                    <img src="../official/static/images/home-right-twitter.png" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else style="position: relative;">
        <div class="home" style="background-size: 100% 100%;">
            <div class="section">
                <div class="page1-box">
                    <div class="d-flex flex-column page1-left-mobile">
                        <img
                            src="../official/static/images/index-logo.png"
                            class="page-logo"
                        />
                        <span class="title">
                            play for token，build your manor!
                        </span>
                    </div>
                    <img class="page1-video-mobile" src="../official/static/images/icon_5.png">
                </div>
                <!-- <div class="home-list">
                    <div class="data-view">
                        <div>Users</div>
                        <div class="desc">22,937,190</div>
                    </div>
                    <div class="data-view">
                        <div>Daily Users</div>
                        <div class="desc">1,627,148</div>
                    </div>
                    <div class="data-view">
                        <div>Onchain Users</div>
                        <div class="desc">1,215,097</div>
                    </div>
                    <div class="data-view">
                        <div>Onchain Txs</div>
                        <div class="desc">13,714,997</div>
                    </div>
                    <div class="data-view">
                        <div>X Followers</div>
                        <div class="desc">1,735,496</div>
                    </div>
                    <div class="data-view">
                        <div>Channel subscribers</div>
                        <div class="desc">4,486,779</div>
                    </div>
                </div> -->

                <div>
                    <div class="p3-carousel-left">
                        <img src="../official/static/images/logo.png" />
                        <div class="desc">
                            <div>
                                Telegram Mini Game
                                Where every game leads 
                                to an airdrop adventure
                                Play and trade using TG 
                                Game Bot
                            </div>
                        </div>
                        <a class="playbtn" href="/game">Explore game</a>
                    </div>
                    <div class="p3-carousel-right" style="height: auto;">
                        <div>
                            <img src="../official/static/images/p3line01.png" />
                        </div>
                        <div>
                            <img src="../official/static/images/p3line02.png" />
                        </div>
                        <div>
                            <img src="../official/static/images/p3line03.png" />
                        </div>
                        <div>
                            <img src="../official/static/images/p3line04.png" />
                        </div>
                        <div>
                            <img src="../official/static/images/p3line05.png" />
                        </div>
                        <div>
                            <img src="../official/static/images/p3line06.png" />
                        </div>
                    </div>
                </div>

                <div class="page4 d-flex flex-column align-items-center vh-full">
                    <div class="page-common-top d-flex flex-column align-items-center">
                        <div class="page-common-title">Roadmap</div>
                    </div>
                    <div class="roadmap d-flex flex-column">
                        <div class="step-item d-flex">
                            <div class="step-item-left d-flex justify-content-end align-items-center">
                                <img class="img-step img-step1">
                            </div>
                            <div class="d-flex flex-column justify-content-end" style="height: 100%;">
                                <div class="line" style="height: 50%;"></div>
                            </div>
                            <div class="step-item-right d-flex justify-content-start align-items-center">
                                <div class="desc">
                                    Game Launch and User Activation<br />
                                    1.Launch the game and start a 100-day new user acquisition campaign.<br />
                                    2.Players can earn points by inviting friends and playing the game.<br />
                                    3.These points will be exchanged for tokens after the campaign ends.
                                    model.
                                </div>
                            </div>
                            <img class="img-catfoot" src="../official/static/images/page4-step-catfoot-mobile.png">
                        </div>
                        <div class="step-item d-flex">
                            <div class="step-item-left d-flex justify-content-end align-items-center">
                                <img class="img-step img-step2">
                            </div>
                            <div class="line"></div>
                            <div class="step-item-right d-flex justify-content-start align-items-center">
                                <div class="desc"> 
                                    Token Issuance and Trading<br />
                                    1.End the 100-day campaign and issue tokens to players based on their accumulated points. <br />
                                    2.List the tokens on an exchange, enabling free trading for players.<br />
                                    3.Use the revenue accumulated during the campaign to buy back and burn some tokens, maintaining their scarcity and value.
                                </div>
                            </div>
                            <img class="img-catfoot" src="../official/static/images/page4-step-catfoot-mobile.png">
                        </div>
                        <div class="step-item d-flex">
                            <div class="step-item-left d-flex justify-content-end align-items-center">
                                <img class="img-step img-step3">
                            </div>
                            <div class="line"></div>
                            <div class="step-item-right d-flex justify-content-start align-items-center">
                                <div class="desc"> 
                                    In-Game Advertising and Economic Loop<br />
                                    1.Introduce an in-game advertising system, allowing brands to reach players through targeted ads.<br />
                                    2.Use the revenue generated from in-game purchases and advertising to continuously buy back and burn tokens.<br />
                                    3.Establish a sustainable economic loop, where the revenue generated from the game is used to maintain the token economy.
                                </div>
                            </div>
                            <img class="img-catfoot" src="../official/static/images/page4-step-catfoot-mobile.png">
                        </div>
                        <div class="step-item d-flex">
                            <div class="step-item-left d-flex justify-content-end align-items-center">
                                <img class="img-step img-step4">
                            </div>
                            <div class="line" style="height: 50%;"></div>
                            <div class="step-item-right d-flex justify-content-start align-items-center">
                                <div class="desc"> 
                                    Game Development and Platform Expansion<br />
                                    1.Invite other game developers to join the platform, expanding the range of games and creating a one-stop gaming hub. <br />
                                    2.Continuously improve and refine the platform, adding new features and functionalities to enhance the gaming experience.<br />
                                    3.Focus on building a professional game channel and ecosystem, offering a comprehensive gaming experience for players.
                                </div>
                            </div>
                            <img class="img-catfoot" src="../official/static/images/page4-step-catfoot-mobile.png">
                        </div>
                    </div>
                </div>

                <div class="page6">
                    <div class="page6Title">
                        <div class="page-common-title">Partners</div>
                    </div>
                    <div class="partners-grid">
                        <div class="partner-item">
                            <img src="../official/static/images/Telegram.png" />
                        </div>
                        <div class="partner-item">
                            <img src="../official/static/images/Trx.png" />
                        </div>
                        <div class="partner-item">
                            <img src="../official/static/images/Ton.png" />
                        </div>
                        <div class="partner-item">
                            <img src="../official/static/images/Solana.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-block d-flex justify-content-between align-items-center" style="position: absolute;">
            <div class="left">
                <div class="copyright" style="font-size: 0.42rem;margin-left:0;">©2024 META MANOR.COPYRIGHT</div>
                <div class="bottom-link-block d-flex">
                    <div class="d-flex">
                        <a href="#" class="link-item" style="font-size: 0.42rem;">Privacy Policy</a>
                        <!-- <a href="/privacy" class="link-item" style="font-size: 0.42rem;">Privacy Policy</a> -->
                    </div>
                </div>
            </div>
            <div class="right d-flex">
                <a href="https://www.youtube.com/@MetaManor_Official" target="_blank">
					<img src="../official/static/images/youtube.png" style="width: 0.5rem;object-fit: contain;" />
				</a>
                <a href="https://t.me/MetaManor" target="_blank">
                    <img src="../official/static/images/home-right-telegram.png" style="width: 0.5rem;" />
                </a>
                <a href="https://x.com/MetaManor_" target="_blank">
                    <img src="../official/static/images/home-right-twitter.png" style="width: 0.5rem;" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
    name: "HomePage",

    setup() {
        const isMobile = ref(window.innerWidth < 1141); // 初始根据屏幕宽度设置是否为移动端
        // const one = ref(true); // 控制每次轮播图切换的张数为1
        // const swiper = ref(null); // el-carousel 的引用

        // 监听窗口大小变化，动态更新 isMobile 的值
        const handleResize = () => {
            isMobile.value = window.innerWidth < 1141;
        };
        
        // 添加窗口大小变化的事件监听器
        onMounted(() => {
            window.addEventListener("resize", handleResize);
        });

        // 移除窗口大小变化的事件监听器
        onUnmounted(() => {
            window.removeEventListener("resize", handleResize);
        });

        // const goWheel = (event) => {
        //     if (event.deltaY > 0 && one.value) {
        //         swiper.value && swiper.value.next(); // 调用 el-carousel 的下一张方法
        //         one.value = false;
        //         setTimeout(() => {
        //             one.value = true;
        //         }, 500);
        //     }
        //     if (event.deltaY < 0 && one.value) {
        //         swiper.value && swiper.value.prev(); // 调用 el-carousel 的上一张方法
        //         one.value = false;
        //         setTimeout(() => {
        //             one.value = true;
        //         }, 500);
        //     }
        // };

        return {
            isMobile,
            // goWheel,
            // swiper,
        };
    },
});
</script>

<style>
.page6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.page6Title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home {
    background: url('../official/static/images/bg.png');
}
.isPage2 {
    margin: 1.4rem 0 2.08rem;
}
</style>
